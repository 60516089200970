<template>
  <ODNField :error="error" :warning="warning">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
      @click="onFocus"
    />
    <ion-item lines="none">
      <ion-input
        :value="productName"
        :placeholder="
          surveyField.placeholder || $t('fields.dropdown.placeholder')
        "
        readonly
      ></ion-input>
    </ion-item>
    <ion-button color="dark" expand="block" @click="onModalSelect">
      <ion-icon slot="start" :icon="icons.search"></ion-icon>
      {{ $t('buttons.select') }}
    </ion-button>
  </ODNField>
</template>

<script>
import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  modalController,
  toastController,
} from '@ionic/vue';
import { pricetagOutline, qrCodeOutline, search } from 'ionicons/icons';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

import ODNModalGroupedSelect from '@m/odn-modal-grouped-select.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    productName() {
      if (!this.modelValue) return null;

      let name = null;

      this.products.forEach((category) => {
        const found = category.items.find(
          (item) => item.id === parseInt(this.modelValue)
        );

        name = found ? found.name : name;
      });

      return name;
    },
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
    warning() {
      return this.surveyField.warning ? this.$t('warnings.productUUID') : null;
    },
  },
  data() {
    return {
      products: [],
      productsLoading: true,
      icons: {
        pricetagOutline,
        qrCodeOutline,
        search,
      },
    };
  },
  created() {
    this.fetchProducts();
  },
  emits: ['update:modelValue'],
  methods: {
    async fetchProducts() {
      let rawProducts;
      this.productsLoading = true;
      try {
        if (this.offline) {
          const rawData = await Filesystem.readFile({
            path: 'products.json',
            directory: Directory.Data,
          });

          rawProducts = JSON.parse(Base64.decode(rawData.data));
        } else {
          rawProducts = (await APIService.get(`/products?filter=light`)).data;
        }

        const groupedProducts = [];

        rawProducts.forEach((category) => {
          if (category.products) {
            groupedProducts.push({
              name: category.name,
              items: category.products.map((product) => {
                return {
                  id: product.id,
                  name: product.name,
                };
              }),
            });
          }

          category.children.forEach((subcategory) => {
            if (subcategory.products) {
              groupedProducts.push({
                name: `${category.name} - ${subcategory.name}`,
                items: subcategory.products.map((product) => {
                  return {
                    id: product.id,
                    name: product.name,
                  };
                }),
              });
            }
          });
        });

        this.products = groupedProducts;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.products.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.productsLoading = false;
      }
    },
    async onModalSelect() {
      const modal = await modalController.create({
        component: ODNModalGroupedSelect,
        componentProps: {
          title: this.$t('modals.products'),
          groupedItems: this.products,
          selected: parseInt(this.modelValue),
        },
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.$emit('update:modelValue', result.id.toString());
      }
    },
    onFocus() {
      this.onModalSelect();
    },
  },
};
</script>
