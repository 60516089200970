<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
    />
    <img
      v-if="modelValue && modelValue.preview"
      :src="modelValue.preview"
      alt="Image"
    />
    <ion-button color="dark" expand="block" @click="onClick">
      <ion-icon slot="start" :icon="icons.cameraOutline"></ion-icon>
      {{ $t('buttons.picture') }}
    </ion-button>
    <ion-button
      v-if="modelValue"
      color="danger"
      expand="block"
      fill="outline"
      @click="onClear"
    >
      <ion-icon slot="start" :icon="icons.trashOutline"></ion-icon>
      {{ $t('buttons.clear') }}
    </ion-button>
  </ODNField>
</template>

<script>
import {
  Camera,
  CameraResultType,
  CameraDirection,
  CameraSource,
} from '@capacitor/camera';
import { isPlatform } from '@ionic/vue';

import { IonButton, IonIcon } from '@ionic/vue';
import { cameraOutline, trashOutline } from 'ionicons/icons';

import { dataUrlToFile } from '@s/helper';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

export default {
  components: {
    IonButton,
    IonIcon,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: Object,
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      icons: {
        cameraOutline,
        trashOutline,
      },
    };
  },
  emits: ['update:modelValue'],
  methods: {
    async takePicture() {
      try {
        const image = await Camera.getPhoto({
          source: CameraSource.Prompt,
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.DataUrl,
          direction: CameraDirection.Rear,
          webUseInput: !isPlatform('desktop'),
        });
        const file = await dataUrlToFile(
          image.dataUrl,
          `image-${this.surveyField.id}.${image.format}`,
          {
            type: `image/${image.format}`,
          }
        );
        this.$emit('update:modelValue', { preview: image.dataUrl, file });
      } catch (err) {
        return;
      }
    },
    onClick() {
      this.takePicture();
    },
    onClear() {
      this.$emit('update:modelValue', null);
    },
  },
};
</script>
