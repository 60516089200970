<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
      @click="onFocus"
    />
    <ion-item lines="none">
      <ion-textarea
        ref="inputRef"
        @ionChange="onChange"
        :value="modelValue"
        :placeholder="surveyField.placeholder || $t('fields.text.placeholder')"
        :rows="2"
      ></ion-textarea>
    </ion-item>
  </ODNField>
</template>

<script>
import { IonItem, IonTextarea } from '@ionic/vue';
import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

export default {
  components: {
    IonItem,
    IonTextarea,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: String,
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    onFocus() {
      this.$refs.inputRef.$el.setFocus();
    },
  },
};
</script>
