<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
      @click="onFocus"
    />
    <ion-item lines="none">
      <ion-select
        ref="inputRef"
        v-if="furnitures.length > 0"
        :value="parsedModelValue"
        :cancelText="$t('buttons.cancel')"
        :okText="$t('buttons.validate')"
        :placeholder="
          surveyField.placeholder || $t('fields.dropdown.placeholder')
        "
        :interfaceOptions="{
          header:
            surveyField.name ||
            $t(`surveyFields.${surveyField.field.name}.label`),
        }"
        @ionChange="onChange"
      >
        <ion-select-option
          v-for="furniture in furnitures"
          :key="furniture.id"
          :value="furniture.id"
        >
          {{ furniture.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-button color="dark" expand="block" @click="onModalQrcode">
      <ion-icon slot="start" :icon="icons.qrCodeOutline"></ion-icon>
      {{ $t('buttons.scan') }}
    </ion-button>
  </ODNField>
</template>

<script>
import { mapState } from 'vuex';

import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  modalController,
  toastController,
} from '@ionic/vue';
import { qrCodeOutline, search } from 'ionicons/icons';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

import APIService from '@s/api.service';

import ODNModalQrcode from '@m/odn-modal-qrcode.vue';

export default {
  components: {
    IonItem,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonIcon,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('stores', {
      store: 'selectedStore',
    }),
    parsedModelValue() {
      return this.modelValue ? parseInt(this.modelValue) : null;
    },
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      furnitures: [],
      icons: {
        qrCodeOutline,
        search,
      },
    };
  },
  created() {
    this.fetchData();
  },
  emits: ['update:modelValue'],
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        if (this.offline) {
          this.furnitures = this.store.furnitures;
        } else {
          this.furnitures = (
            await APIService.get(
              `/stores/${this.store.id}/furnitures?filter=light`
            )
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.furnitures.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onModalQrcode() {
      const modal = await modalController.create({
        component: ODNModalQrcode,
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.$emit('update:modelValue', result);
      }
    },
    onChange(event) {
      this.$emit('update:modelValue', event.target.value.toString());
    },
    onFocus() {
      this.$refs.inputRef.$el.open();
    },
  },
};
</script>

<style scoped>
ion-select {
  width: 100%;
}
</style>
