<template>
  <div
    class="odn-bloc-text"
    v-html="surveyField.content || surveyField.name"
  ></div>
</template>

<script>
export default {
  props: {
    surveyField: Object,
  },
};
</script>
