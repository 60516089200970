<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
      @click="onFocus"
    />
    <ion-item lines="none">
      <ion-select
        ref="inputRef"
        :multiple="true"
        :value="values"
        :cancelText="$t('buttons.cancel')"
        :okText="$t('buttons.validate')"
        :placeholder="
          surveyField.placeholder || $t('fields.checkbox.placeholder')
        "
        :interfaceOptions="{
          header:
            surveyField.name ||
            $t(`surveyFields.${surveyField.field.name}.label`),
        }"
        @ionChange="onChange"
      >
        <ion-select-option
          v-for="(choice, index) in choices"
          :key="index"
          :value="choice"
        >
          {{ choice }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ODNField>
</template>

<script>
import { IonItem, IonSelect, IonSelectOption } from '@ionic/vue';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

export default {
  components: {
    IonItem,
    IonSelect,
    IonSelectOption,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: String,
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    values() {
      return this.modelValue?.split(',');
    },
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      choices: this.surveyField.content?.split(','),
    };
  },
  emits: ['update:modelValue'],
  methods: {
    onChange(event) {
      this.$emit('update:modelValue', event.target.value.join(','));
    },
    onFocus() {
      this.$refs.inputRef.$el.open();
    },
  },
};
</script>

<style scoped>
ion-select {
  width: 100%;
}
</style>
