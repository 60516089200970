<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
      @click="onFocus"
    />
    <ion-item lines="none">
      <ion-select
        ref="inputRef"
        v-if="offline || locationStatuses.length > 0"
        :value="parsedModelValue"
        :cancelText="$t('buttons.cancel')"
        :okText="$t('buttons.validate')"
        :placeholder="
          surveyField.placeholder || $t('fields.dropdown.placeholder')
        "
        :interfaceOptions="{
          header:
            surveyField.name ||
            $t(`surveyFields.${surveyField.field.name}.label`),
        }"
        :disabled="loading"
        @ionChange="onChange"
      >
        <ion-select-option
          v-for="locationStatus in offline
            ? getOptionSet('locationStatuses').items
            : locationStatuses"
          :key="locationStatus.id"
          :value="locationStatus.id"
        >
          {{ $t(`locationStatuses.${locationStatus.name}`) }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ODNField>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  IonItem,
  IonSelect,
  IonSelectOption,
  toastController,
} from '@ionic/vue';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonItem,
    IonSelect,
    IonSelectOption,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('offline', ['getOptionSet']),
    parsedModelValue() {
      return this.modelValue ? parseInt(this.modelValue) : null;
    },
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      locationStatuses: [],
      loading: false,
    };
  },
  created() {
    this.fetchData();
  },
  emits: ['update:modelValue'],
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        if (!this.offline) {
          this.locationStatuses = (
            await APIService.get('/location-statuses')
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.locationStatuses.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    onChange(event) {
      this.$emit('update:modelValue', event.target.value.toString());
    },
    onFocus() {
      this.$refs.inputRef.$el.open();
    },
  },
};
</script>

<style scoped>
ion-select {
  width: 100%;
}
</style>
