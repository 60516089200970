<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
    />
    <input
      ref="inputFile"
      type="file"
      accept="*"
      style="display: none"
      @change="onChange"
    />
    <div
      v-if="modelValue && modelValue.preview"
      class="odn-field-upload-generic-preview"
    >
      <ion-icon :icon="icons.documentOutline"></ion-icon>
      {{ modelValue.preview }}
    </div>
    <ion-button color="dark" expand="block" @click="onClick">
      <ion-icon slot="start" :icon="icons.documentOutline"></ion-icon>
      {{ $t('buttons.file') }}
    </ion-button>
    <ion-button
      v-if="modelValue"
      color="danger"
      expand="block"
      fill="outline"
      @click="onClear"
    >
      <ion-icon slot="start" :icon="icons.trashOutline"></ion-icon>
      {{ $t('buttons.clear') }}
    </ion-button>
  </ODNField>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { documentOutline, trashOutline } from 'ionicons/icons';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

export default {
  components: {
    IonButton,
    IonIcon,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: Object,
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      icons: {
        documentOutline,
        trashOutline,
      },
    };
  },
  emits: ['update:modelValue'],
  methods: {
    onClick() {
      this.$refs.inputFile.click();
    },
    onChange(event) {
      this.$emit('update:modelValue', {
        preview: event.target.files[0].name,
        file: event.target.files[0],
      });
    },
    onClear() {
      this.$refs.inputFile.value = null;
      this.$emit('update:modelValue', null);
    },
  },
};
</script>

<style lang="scss">
.odn-field-upload-generic-preview {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 16px 8px 16px;

  ion-icon {
    margin-right: 8px;
  }
}
</style>
