<template>
  <div class="odn-bloc-title">
    {{ surveyField.content || surveyField.name }}
  </div>
</template>

<script>
export default {
  props: {
    surveyField: Object,
  },
};
</script>

<style lang="scss">
.odn-bloc-title {
  font-size: 2rem;
}
</style>
