<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
    />
    <input
      ref="inputFile"
      type="file"
      accept="video/*"
      capture
      style="display: none"
      @change="onChange"
    />
    <video
      ref="videoPlayer"
      v-if="modelValue && modelValue.preview"
      width="400"
      controls
    >
      <source :src="modelValue.preview" />
    </video>
    <ion-button color="dark" expand="block" @click="onClick">
      <ion-icon slot="start" :icon="icons.videocamOutline"></ion-icon>
      {{ $t('buttons.video') }}
    </ion-button>
    <ion-button
      v-if="modelValue"
      color="danger"
      expand="block"
      fill="outline"
      @click="onClear"
    >
      <ion-icon slot="start" :icon="icons.trashOutline"></ion-icon>
      {{ $t('buttons.clear') }}
    </ion-button>
  </ODNField>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { videocamOutline, trashOutline } from 'ionicons/icons';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

export default {
  components: {
    IonButton,
    IonIcon,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: Object,
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      icons: {
        videocamOutline,
        trashOutline,
      },
    };
  },
  emits: ['update:modelValue'],
  methods: {
    onClick() {
      this.$refs.inputFile.click();
    },
    onChange(event) {
      this.$emit('update:modelValue', {
        preview: URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
      });
      this.$refs.videoPlayer.load();
    },
    onClear() {
      this.$refs.inputFile.value = null;
      this.$emit('update:modelValue', null);
    },
  },
};
</script>
