<template>
  <ODNField :error="error">
    <ODNLabel
      :label="
        surveyField.name || $t(`surveyFields.${surveyField.field.name}.label`)
      "
      :style="{ color: surveyField.field.color || null }"
      @click="onFocus"
    />
    <ion-item lines="none">
      <ion-input
        ref="inputRef"
        :value="modelValue"
        :placeholder="surveyField.placeholder || $t('fields.text.placeholder')"
        @ionChange="onChange"
      ></ion-input>
    </ion-item>
    <ion-button color="dark" expand="block" @click="onModalBarcode">
      <ion-icon slot="start" :icon="icons.barcodeOutline"></ion-icon>
      {{ $t('buttons.scan') }}
    </ion-button>
  </ODNField>
</template>

<script>
import {
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  modalController,
} from '@ionic/vue';
import { barcodeOutline } from 'ionicons/icons';

import ODNField from '@c/fields/odn-field.vue';
import ODNLabel from '@c/odn-label.vue';

import ODNModalBarcode from '@m/odn-modal-barcode.vue';

export default {
  components: {
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
    ODNField,
    ODNLabel,
  },
  props: {
    modelValue: String,
    surveyField: Object,
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    error() {
      return this.submitted &&
        this.surveyField.isRequired &&
        !this.surveyField.response
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      icons: {
        barcodeOutline,
      },
    };
  },
  emits: ['update:modelValue'],
  methods: {
    async onModalBarcode() {
      const modal = await modalController.create({
        component: ODNModalBarcode,
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.$emit('update:modelValue', result);
      }
    },
    onChange(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    onFocus() {
      this.$refs.inputRef.$el.setFocus();
    },
  },
};
</script>
