<template>
  <form @submit.prevent="onSave" class="odn-survey">
    <!-- Notes -->
    <div v-if="survey.notes" class="odn-survey-notes">
      <div>
        <ion-icon :icon="icons.informationCircleOutline"></ion-icon>
      </div>
      <div v-html="survey.notes" class="odn-survey-notes-content"></div>
    </div>

    <!-- Survey fields -->
    <div class="odn-survey-fields">
      <div v-if="loading" class="odn-survey-fields-loading">
        <ion-spinner></ion-spinner>
      </div>
      <div
        v-for="surveyField in survey.surveyFields"
        :key="surveyField.id"
        class="odn-survey-field"
      >
        <component
          :is="getTemplate(surveyField.field.template)"
          :survey-field="surveyField"
          :submitted="isSubmitted"
          :offline="offline"
          v-model="surveyField.response"
        ></component>
      </div>
    </div>

    <!-- Signature -->
    <div v-if="survey.hasSignatures" class="odn-survey-signature">
      <ODNFormField>
        <template #alt-label>
          {{ $t('fields.signature.label') }}
        </template>
        <template #alt-content>
          <div class="odn-pay-4" v-if="signature.picture">
            <img :src="signature.picture" :alt="$t('fields.signature.label')" />
            <div
              v-if="signature.lastname || signature.firstname"
              class="ion-text-center"
            >
              {{ signature.lastname }}
              {{ signature.firstname }}
            </div>
          </div>
          <ion-button color="light" expand="block" @click="onModalSignature">
            <ion-icon slot="start" :icon="icons.pencilOutline"></ion-icon>
            {{ $t('buttons.sign') }}
          </ion-button>
        </template>
      </ODNFormField>
    </div>

    <!-- Comment -->
    <div class="odn-survey-response-comment">
      <ODNFormField
        :label="$t('fields.comment.label')"
        label-position="stacked"
      >
        <ion-textarea
          v-model="comment"
          :placeholder="$t('fields.comment.placeholder')"
          :auto-grow="true"
        ></ion-textarea>
      </ODNFormField>
    </div>

    <!-- Response status-->
    <div class="odn-survey-response-status">
      <ODNField :error="responseStatusError">
        <ion-item lines="none">
          <ion-label position="stacked">
            {{ $t('fields.responseStatus.label') }}
          </ion-label>
          <ion-select
            :placeholder="$t('fields.responseStatus.placeholder')"
            v-model="responseStatusId"
            :cancelText="$t('buttons.cancel')"
            :okText="$t('buttons.validate')"
            :disabled="responseStatusesLoading || loading || saving"
          >
            <ion-select-option
              v-for="responseStatus in offline
                ? getOptionSet('responseStatuses').items
                : responseStatuses"
              :key="responseStatus.id"
              :value="responseStatus.id"
            >
              {{ $t(`responseStatuses.${responseStatus.name}`) }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ODNField>
    </div>
    <div v-if="errors" class="odn-survey-errors">
      {{ $t('errors.form') }}
    </div>
    <div v-if="warnings.length > 0" class="odn-survey-warnings">
      {{ $t('warnings.form') }}
    </div>
    <div v-if="saving" class="odn-survey-upload-progress">
      <div v-if="uploading" class="odn-survey-upload-progress-label">
        {{ $t('labels.uploading') }}
      </div>
      <div v-else class="odn-survey-upload-progress-label">
        {{ $t('labels.saving') }}
      </div>
      <ion-progress-bar
        :type="uploading ? 'determinate' : 'indeterminate'"
        :value="uploadingPercent"
      ></ion-progress-bar>
    </div>
    <div class="odn-survey-buttons">
      <ion-button
        v-if="offline"
        type="submit"
        color="primary"
        :disabled="saving || errors"
        expand="block"
      >
        {{ !warningChecked ? $t('buttons.save') : $t('buttons.saveAnyway') }}
        <ODNSpinner v-if="saving" slot="end" />
        <ion-icon v-else slot="end" :icon="icons.save"></ion-icon>
      </ion-button>
      <ion-button
        v-else
        type="submit"
        color="primary"
        :disabled="loading || saving || errors"
        expand="block"
      >
        {{ !warningChecked ? $t('buttons.send') : $t('buttons.sendAnyway') }}
        <ODNSpinner v-if="loading || saving" slot="end" />
        <ion-icon v-else slot="end" :icon="icons.send"></ion-icon>
      </ion-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import {
  IonSpinner,
  IonButton,
  IonIcon,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonProgressBar,
  IonTextarea,
  toastController,
  modalController,
} from '@ionic/vue';
import {
  informationCircleOutline,
  pencilOutline,
  save,
  send,
} from 'ionicons/icons';

import { dataUrlToFile } from '@s/helper';

import ODNField from '@c/fields/odn-field.vue';
import ODNBlocTitle from '@c/blocs/odn-bloc-title.vue';
import ODNBlocText from '@c/blocs/odn-bloc-text.vue';
import ODNBlocImage from '@c/blocs/odn-bloc-image.vue';
import ODNBlocVideo from '@c/blocs/odn-bloc-video.vue';
import ODNFieldText from '@c/fields/odn-field-text.vue';
import ODNFieldTextMultiline from '@c/fields/odn-field-text-multiline.vue';
import ODNFieldCheckbox from '@c/fields/odn-field-checkbox.vue';
import ODNFieldRadio from '@c/fields/odn-field-radio.vue';
import ODNFieldDropdown from '@c/fields/odn-field-dropdown.vue';
import ODNFieldUploadImage from '@c/fields/odn-field-upload-image.vue';
import ODNFieldUploadVideo from '@c/fields/odn-field-upload-video.vue';
import ODNFieldUploadGeneric from '@c/fields/odn-field-upload-generic.vue';
import ODNFieldBarcode from '@c/fields/odn-field-barcode.vue';
import ODNFieldIMEI from '@c/fields/odn-field-imei.vue';
import ODNFieldQRCode from '@c/fields/odn-field-qrcode.vue';
import ODNFieldProduct from '@c/fields/odn-field-product.vue';
import ODNFieldUnit from '@c/fields/odn-field-unit.vue';
import ODNFieldLocationStatus from '@c/fields/odn-field-location-status.vue';
import ODNFieldFurniture from '@c/fields/odn-field-furniture.vue';
import ODNFormField from '@c/odn-form-field.vue';
import ODNSpinner from '@c/odn-spinner.vue';

import ODNModalSignature from '@m/odn-modal-signature.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonSpinner,
    IonButton,
    IonIcon,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonProgressBar,
    IonTextarea,
    ODNField,
    ODNFormField,
    ODNSpinner,
  },
  props: {
    survey: {
      type: Object,
      default: null,
    },
    response: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    offline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('stores', {
      store: 'selectedStore',
    }),
    ...mapGetters('offline', ['getOptionSet']),
    errors() {
      return (
        this.isSubmitted &&
        this.survey.surveyFields?.filter(
          sf => sf.field.isForm && sf.isRequired && !sf.response
        ).length > 0
      );
    },
    responseStatusError() {
      return this.isSubmitted && !this.responseStatusId
        ? this.$t('errors.required')
        : null;
    },
  },
  data() {
    return {
      saving: false,
      uploading: false,
      uploadingPercent: 0,
      comment: null,
      responseStatusId: null,
      responseStatuses: [],
      responseStatusesLoading: true,
      signature: {
        lastname: null,
        firstname: null,
        picture: null,
      },
      newSignature: false,
      isSubmitted: false,
      icons: {
        informationCircleOutline,
        pencilOutline,
        save,
        send,
      },
      offlineResponse: {
        store: null,
        response: null,
        values: null,
        signature: null,
        uploads: null,
      },
      warnings: [],
      warningChecked: false,
    };
  },
  mounted() {
    this.fetchResponseStatuses();

    if (this.response) {
      this.comment = this.response.comment;
    }

    if (this.response && this.response.responseStatus) {
      this.responseStatusId = this.response.responseStatus.id;
    }

    if (this.response && this.response.responseSignature) {
      this.signature.lastname = this.response.responseSignature.lastname;
      this.signature.firstname = this.response.responseSignature.firstname;
      this.signature.picture = this.response.responseSignature.signature;
    }
  },
  emits: ['saved'],
  methods: {
    ...mapActions('offline', ['saveOfflineResponse']),
    getTemplate(template) {
      return {
        'bloc-title': ODNBlocTitle,
        'bloc-text': ODNBlocText,
        'bloc-image': ODNBlocImage,
        'bloc-video': ODNBlocVideo,
        'field-text': ODNFieldText,
        'field-text-multiline': ODNFieldTextMultiline,
        'field-checkbox': ODNFieldCheckbox,
        'field-radio': ODNFieldRadio,
        'field-dropdown': ODNFieldDropdown,
        'field-upload-image': ODNFieldUploadImage,
        'field-upload-video': ODNFieldUploadVideo,
        'field-upload-generic': ODNFieldUploadGeneric,
        'field-barcode': ODNFieldBarcode,
        'field-imei': ODNFieldIMEI,
        'field-qrcode': ODNFieldQRCode,
        'field-qrcode-install': ODNFieldQRCode,
        'field-qrcode-uninstall': ODNFieldQRCode,
        'field-product': ODNFieldProduct,
        'field-unit': ODNFieldUnit,
        'field-location-status': ODNFieldLocationStatus,
        'field-furniture': ODNFieldFurniture,
      }[template];
    },
    async fetchResponseStatuses() {
      this.responseStatusesLoading = true;
      try {
        if (!this.offline) {
          this.responseStatuses = (
            await APIService.get('/response-statuses')
          ).data;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.responseStatuses.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.responseStatusesLoading = false;
      }
    },
    async createResponse(values) {
      return (await APIService.post(`/responses`, values)).data;
    },
    async updateResponse(id, values) {
      return (await APIService.put(`/responses/${id}`, values)).data;
    },
    async createResponseValues(responseId, values) {
      if (values.values && values.values.length <= 0) return true;
      return (
        await APIService.post(`/responses/${responseId}/values/bulk`, values)
      ).data;
    },
    async onModalSignature() {
      const modal = await modalController.create({
        component: ODNModalSignature,
      });
      await modal.present();

      const result = (await modal.onDidDismiss()).data;

      if (result) {
        this.signature = result;
        this.newSignature = true;
      }
    },
    async saveSignature(responseId) {
      const { lastname, firstname, picture } = this.signature;
      try {
        const randomNumber = new Date().getTime();
        const file = await dataUrlToFile(
          picture,
          `signature-response-${responseId}-${randomNumber}.png`,
          {
            type: `image/png`,
          }
        );

        const formData = new FormData();
        formData.append('file', file);

        if (lastname) {
          formData.append('lastname', lastname);
        }

        if (firstname) {
          formData.append('firstname', firstname);
        }

        return await APIService.upload(
          `/responses/${responseId}/signature`,
          formData
        );
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.responseSignature.post.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
      }
    },
    async uploadResponseValues(responseId, values) {
      for (const [index, value] of values.entries()) {
        const formData = new FormData();
        formData.append('surveyFieldId', value.surveyFieldId);
        formData.append('file', value.file);

        this.uploadingPercent = index / values.length;

        await APIService.upload(
          `/responses/${responseId}/values/upload`,
          formData
        );
      }
    },
    async onSave() {
      if (this.warningChecked) return await this.processSave();

      this.warnings = [];
      for (const sf of this.survey.surveyFields) {
        if (sf.field.template == 'field-product') {
          // Searching the field-unit child
          const child = this.survey.surveyFields?.filter(
            surveyField =>
              surveyField.parent?.id == sf.id &&
              surveyField.field.template == 'field-unit'
          );
          if (child[0] && child[0].response) {
            const jsonResponse = JSON.parse(child[0].response);
            const unit = (
              await APIService.get(`/units/by-uuid/${jsonResponse.uuid}`)
            ).data;
            if (unit && unit.product.id != sf.response) {
              child[0].warning = true;
              sf.warning = true;
              this.warnings.push({ child: child[0], product: sf });
            }
          }
        }
      }
      if (this.warnings.length <= 0) {
        await this.processSave();
      } else {
        this.warningChecked = true;
      }
    },
    async processSave() {
      this.isSubmitted = true;

      if (this.survey.hasSignatures && !this.signature.picture) {
        const toast = await toastController.create({
          message: this.$t('fields.signature.error'),
          color: 'danger',
          duration: 2000,
        });
        toast.present();
        return;
      }

      if (this.errors || !this.responseStatusId) {
        return false;
      }

      this.saving = true;

      // ----------------------------------------------------------------------
      // 1. Create/Update the response and get newly created ID
      // ----------------------------------------------------------------------
      let surveyResponse;

      if (this.offline) {
        this.offlineResponse.store = {
          id: this.store.id,
          store: this.store.name,
          retailer: this.store.retailer.name,
        };
      }

      try {
        const data = {
          surveyId: this.survey.id,
          storeId: this.store ? this.store.id : parseInt(this.$route.params.id), // FIXME: get vuex state correctly saved and loaded
          responseStatusId: parseInt(this.responseStatusId),
          comment: this.comment,
        };
        if (this.offline) {
          this.offlineResponse.response = data;
        } else {
          if (this.response && this.response.id) {
            surveyResponse = await this.updateResponse(this.response.id, {
              responseStatusId: parseInt(this.responseStatusId),
              comment: this.comment,
            });
          } else {
            surveyResponse = await this.createResponse(data);
          }
        }
      } catch (err) {
        this.saving = false;
        const toast = await toastController.create({
          message: this.$t('messages.surveyResponse.post.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      }

      // ----------------------------------------------------------------------
      // 2A. Create values array for non-upload fields...
      // ----------------------------------------------------------------------

      // Return all children for the submitted parent id
      const FindChildren = parentId => {
        return this.survey.surveyFields.filter(
          sf => sf.parent && sf.parent.id === parentId
        );
      };

      const FindParent = id => {
        return this.survey.surveyFields.find(sf => sf.id === id);
      };

      // Loop through all survey fields
      this.survey.surveyFields.forEach(sf => {
        // If the survey field is a form, not upload and has response (value)
        if (sf.field.isForm && !sf.field.isUpload && sf.response) {
          // If no previous answer OR is different
          if (
            !sf.responseValues ||
            (sf.responseValues &&
              sf.responseValues[0] &&
              sf.responseValues[0].value !== sf.response)
          ) {
            let children = [];

            // If it has a parent
            if (sf.parent) {
              const parent = FindParent(sf.parent.id);
              parent.linkedAndEdited = true;
              parent.response = parent.response || null;
              children = FindChildren(parent.id);
            } else {
              sf.linkedAndEdited = true;
              sf.response = sf.response || null;
              children = FindChildren(sf.id);
            }

            children.forEach(child => {
              child.linkedAndEdited = true;
              child.response = child.response || null;
            });
          }
        }
      });

      // Create values
      const surveyResponseValues = {
        values: this.survey.surveyFields
          .filter(sf => {
            if (sf.linkedAndEdited) {
              return true;
            } else if (sf.field.isForm && !sf.field.isUpload && sf.response) {
              if (
                sf.responseValues &&
                sf.responseValues[0] &&
                sf.responseValues[0].value === sf.response
              ) {
                return false;
              } else {
                return true;
              }
            }
            return false;
          })
          .map(sf => {
            return {
              surveyFieldId: sf.id,
              value: sf.response,
            };
          }),
      };

      // ----------------------------------------------------------------------
      // 2B. ...and bulk save all for the new response ID...
      // ----------------------------------------------------------------------
      try {
        if (this.offline) {
          this.offlineResponse.values = surveyResponseValues;
        } else {
          await this.createResponseValues(
            surveyResponse.id,
            surveyResponseValues
          );
        }
      } catch (err) {
        this.saving = false;
        const toast = await toastController.create({
          message: this.$t('messages.surveyResponseValues.post.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      }

      // ----------------------------------------------------------------------
      // 2C. ...and finally save signature (if required)
      // ----------------------------------------------------------------------
      if (this.survey.hasSignatures) {
        if (this.offline) {
          this.offlineResponse.signature = this.signature;
        } else {
          if (this.newSignature) {
            await this.saveSignature(surveyResponse.id);
          }
        }
      }

      // ----------------------------------------------------------------------
      // 3A. Prepare all upload fields (isUpload)...
      // ----------------------------------------------------------------------
      const surveyFieldUploads = this.survey.surveyFields
        .filter(sf => sf.field.isUpload && sf.response && sf.response.file)
        .map(sf => {
          return {
            surveyFieldId: sf.id,
            file: sf.response.file,
            type: sf.response.file.type,
          };
        });

      // ----------------------------------------------------------------------
      // 3B. ...and upload them all
      // ----------------------------------------------------------------------
      this.uploading = true;

      if (this.offline) {
        this.offlineResponse.uploads = surveyFieldUploads;
      } else {
        try {
          await this.uploadResponseValues(
            surveyResponse.id,
            surveyFieldUploads
          );
        } catch (err) {
          this.saving = false;
          const toast = await toastController.create({
            message: this.$t('messages.surveyResponseValuesUpload.post.error'),
            color: 'danger',
            duration: 2000,
          });
          return toast.present();
        }
      }

      if (this.offline) {
        await this.saveOfflineResponse(this.offlineResponse);
      }

      this.saving = false;
      this.uploading = false;

      this.$emit('saved');
    },
  },
};
</script>

<style lang="scss">
.odn-survey {
  &-notes {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px;

    ion-icon {
      font-size: 30px;
    }

    &-content {
      margin-left: 16px;
    }
  }

  &-fields {
    padding: 16px;

    &-loading {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      padding: 16px;
    }
  }

  &-field {
    margin-bottom: 8px;
  }

  &-signature {
    padding: 16px;
  }

  &-response-comment {
    padding: 0 16px;
  }

  &-response-status {
    padding: 16px 16px 0 16px;
    margin-bottom: 8px;
  }

  &-errors {
    margin: 0 16px;
    padding: 16px;
    font-size: 0.9em;
    color: var(--ion-color-danger);
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
    border-radius: 8px;
  }

  &-warnings {
    margin: 0 16px;
    padding: 16px;
    font-size: 0.9em;
    color: var(--ion-color-warning);
    background-color: rgba(var(--ion-color-warning-rgb), 0.2);
    border-radius: 8px;
  }

  &-upload-progress {
    margin: 16px 16px 0 16px;
    padding: 16px;
    background-color: rgba(var(--ion-color-light-contrast-rgb), 0.05);
    border-radius: 8px;

    &-label {
      margin-bottom: 16px;
    }
  }

  &-buttons {
    padding: 16px;
  }
}
</style>
