<template>
  <div class="odn-bloc-video">
    <video controls :title="surveyField.name || surveyField.content">
      <source :src="surveyField.content" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  props: {
    surveyField: Object,
  },
};
</script>

<style lang="scss">
.odn-bloc-video {
  > video {
    width: 100%;
    display: block;
    border-radius: 8px;
  }
}
</style>
