<template>
  <div class="odn-bloc-image">
    <img
      :src="surveyField.content"
      :alt="surveyField.name || surveyField.content"
    />
  </div>
</template>

<script>
export default {
  props: {
    surveyField: Object,
  },
};
</script>

<style lang="scss">
.odn-bloc-image {
  > img {
    display: block;
    border-radius: 8px;
  }
}
</style>
